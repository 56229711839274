<template>
  <div class="background">
    <div class="z_nav">
      <div class="typeArea ul">
        <div class="a" @click="tomyCollectionarticles()">我的收藏</div>
        <div class="a active" @click="tomyRecentInformation()">近期浏览</div>
      </div>
    </div>
    <div class="typeArea content">
      <div class="z_index">
        <div class="left">
          <div class="content-top" v-if="Historicalrecordlist.length > 0">
            <div class="content-item new-project">
              <!-- <div class="header">
                <div class="header-left">
                  <div class="vertical-line"></div>
                  <div>我的近期浏览记录</div>
                </div>
              </div> -->
              <div class="content-main">
                <table style="display: none">
                  <thead>
                    <tr>
                      <td>记录标题</td>
                      <td>查看时间</td>
                      <!-- <td>功能</td> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in Historicalrecordlist" :key="item.id">
                      <td :title="item.title">
                        {{ item.title }}
                        <span v-if="item.title == null">——</span>
                      </td>
                      <td :title="item.dateformate">{{ item.dateformate }}</td>

                      <!-- <td>
                    <div
                      class="button-see"
                      @click="toReportingGuideDetail(item)"
                    >查看</div>
                  </td> -->
                    </tr>
                  </tbody>
                </table>
                <div
                  class="content-item1"
                  v-for="item in Historicalrecordlist.slice(0, shuliang)"
                  :key="item.id"
                >
                  <div class="item-content">
                    <div
                      class="content-title"
                      @click="toReportingGuideDetail(item)"
                    >
                    <div
                    v-if="item.HITS>700"
                    class="re"
                  >
                  热
                  </div>
                      <div class="title-text">{{ item.PROJECT_NAME }}</div>
                    </div>
                    <div class="content-info">
                      <div class="info-item long">
                        <div class="item-describe">发布机构：</div>
                        <div class="item-info">
                          <span
                            v-if="
                              item.PROJECT_GOVERNMENT == null ||
                              item.PROJECT_GOVERNMENT == ''
                            "
                            >——</span
                          >
                          <span class="fbjg" v-else>{{
                            item.PROJECT_GOVERNMENT
                          }}</span>
                        </div>
                      </div>
                      <div class="info-item">
                        <div class="item-describe">资助力度：</div>
                        <div class="item-info">
                          <span
                            v-if="
                              item.PROJECT_FUNDS == '' ||
                              item.PROJECT_FUNDS == null
                            "
                            >——</span
                          ><span v-else>{{ item.PROJECT_FUNDS }}万元</span>
                        </div>
                      </div>
                      <div class="info-item">
                        <div class="item-describe">发布时间：</div>
                        <div class="item-info">{{ item.PROJECT_DATE }}</div>
                      </div>
                      <div class="info-item long">
                        <div class="item-describe">资助范围：</div>
                        <div class="item-info">
                          <span v-if="item.PROJECT_GOVERNMENT_PRO">{{
                            item.PROJECT_GOVERNMENT_PRO
                          }}</span>
                          <span v-else>——</span>
                        </div>
                      </div>
                      <div class="info-item">
                        <div class="item-describe">截至时间：</div>
                        <div class="item-info">{{ item.PROJECT_DATE_END }}</div>
                      </div>
                    </div>
                    <div class="content-tag">
                      <div
                        class="tag-item"
                        v-for="(ite, index) in item.PROJECT_INDUSTRY"
                        :key="index"
                        @mouseenter="tagmouseenter($event)"
                        @mouseleave="tagmouseleave($event)"
                      >
                        {{ ite }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
        class="seemore"
        @click="getMoreNews"
      >
        查看更多内容
      </div>
            </div>
            <!-- <template>
          <div class="paging">
            <ul class="pagination">
              <li
                @click="prePagezn()"
                :class="pageNozn<=1?'disabled':''"
              >
                <a>&lt; </a>
              </li>
              <li
                v-for="(item,index) in pagezns"
                :class="pageNozn==item?'active':''"
                :key="index"
                @click="curPagezn(item)"
              >
                <a href="javascript:void(0)">{{item}}</a>
              </li>
              <li
                @click="nextPagezn()"
                :class="pageNozn>=pageTotalzn?'disabled':''"
              >
                <a>
                  &gt;
                </a>
              </li>
            </ul>
          </div>
        </template> -->
          </div>
          <div class="content-top nocollection" v-else style="flex-direction: column;">
            <img
              src="../../../assets/image/我的订阅_slices/未订阅.png"
              alt=""
              style="max-width: 88%"
            />
            <div style="font-size:20px;color:#282828;line-height:1.5;margin:110px 0;text-align: center;">
              您还没有收藏任何内容
            </div>
          </div>
        </div>
        <div class="right">
          <div class="z_xgkt">
            <div class="title">推荐阅读</div>
            <ul>
              <Li
                v-for="(item, index) in tonghang.slice(0, 5)"
                :key="index"
                @click="toReportingGuideDetail(item)"
              >
                <h1>{{ item.PROJECT_NAME }}</h1>
                <h2>{{ item.PROJECT_DATE_END }}</h2>
              </Li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Historicalrecordlist } from "../../../api/myRecentInformation";
import { formatDate } from "../../../util/tools";
import { Addhistoricalrecord } from '../../../api/myRecentInformation'
export default {
  data() {
    return {
      reportingGuidelinestotal: 3,
      ReportingGuidelinesList: [{ id: 1 }],
      Historicalrecordlist: [],
      // 指南
      pageTotalzn: 10,
      // 指南
      pageNozn: 1,
      // 指南
      pagezn: -1,
      // 指南
      pageSizezn: -1,
      // 指南
      rowszn: -1,
      // 指南
      isreadfenyezn: true,
      tonghang: [],
      shuliang:5
    };
  },
  created() {
    // 获取初始数据
    this.getdata();
    this.getPeerReview();
    this.navxuanzhong();
  },
  watch: {
    Historicalrecordlist: function (newVal, oldVal) {
      const flag = this.$data.isreadfenyezn;
      // TO DO
      // eslint-disable-next-line eqeqeq
      if (flag == true) {
        console.log("newVal:", newVal);
        console.log("oldVal:", oldVal);
        const len = newVal.length;
        this.$data.pageTotalzn =
          len % 10 === 0 ? Math.floor(len / 10) : Math.floor(len / 10) + 1;
      }
    },
  },
  computed: {
    pagezns() {
      const start = this.pageNozn;
      const end = this.pageTotalzn;
      if (end < 10) return end;
      if (start <= 5) {
        return [1, 2, 3, 4, 5, 6, "...", end];
      } else if (start > end - 5) {
        return [1, "...", end - 5, end - 4, end - 3, end - 2, end - 1, end];
      } else {
        return [
          1,
          "...",
          start - 3,
          start - 2,
          start - 1,
          start,
          start + 1,
          start + 2,
          "...",
          end,
        ];
      }
    },
  },
  methods: {
    // 跳转到最新课题申报信息详情页
    toReportingGuideDetail (item) {
      localStorage.setItem('navActive', 2)
      this.$store.commit('setnavActive', 2)
      const id = item.IN_PROJECT_GOV_ID
      const uid = this.$store.state.userid
      const params = {
        title_id: id,
        user_id: uid
        // type: 1
      }
      Addhistoricalrecord(params).then((res) => {
        console.log('添加历史记录成功', res)
      }, (err) => {
        console.log('添加历史记录异常', err)
      })
      let md = item.PROJECT_GOV_MD5
      if (md === null) {
        md = ''
      }
      // debugger
      this.$router.push({
        name: 'reportingGuidelinesDetails',
        params: {
          id: id
        },
        // query: {
        //   PROJECT_GOV_MD5: md
        // }
      })
      window._czc.push(['_trackEvent', '点击', '跳转到最新课题申报详情页'])
    },
    navxuanzhong() {
      localStorage.setItem("navActive", 0);
      this.$store.commit("setnavActive", 0);
    },
    //同行再看
    getPeerReview() {
      const that = this;
      const axios = require("axios");
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      const appurl = "https://data.keyanpro.com/keyanpro3/";
      formData.append("user_id", this.$store.state.userid); //添加文件对象 ，data中设置的
      axios({
        url: appurl + "PeerReview",
        method: "post",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
          that.tonghang = res.data.resultList;
          console.log(that.tonghang);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getdata() {
      this.gethistoricalrecordlist();
    },
    // 获取浏览历史记录列表
    gethistoricalrecordlist() {
      const that = this;
      const id = this.$store.state.userid;
      const params = {
        user_id: id,
      };
      Historicalrecordlist(params).then(
        (res) => {
          console.log("浏览历史返回数据", res);
          that.formatedata(res.data);
          that.$data.Historicalrecordlist = this.sortDesByKey(
            res.data,
            "read_time"
          );
        },
        (err) => {
          console.log("浏览历史返回数据异常", err);
        }
      );
    },
    // 数据按照字段名降序排序
    sortDesByKey(ary, key) {
      return ary.sort((a, b) => {
        const x = a[key];
        const y = b[key];
        return x < y ? 1 : x > y ? -1 : 0;
      });
    },
    // 时间处理
    formatedata(arr) {
      arr.forEach((ele) => {
        ele.dateformate = formatDate(Number(ele.read_time));
      });
    },
    // 指南
    prePagezn() {
      if (this.pageNozn > 1) {
        this.pageNozn = Number(this.pageNozn) - 1;
        const i = this.pageNozn;
        this.$data.subscribeGuideList =
          this.$data.subscribeGuideListbeifen.slice(
            (i - 1) * 10,
            (i - 1) * 10 + 10
          );
        this.$data.isreadfenyezn = false;
        // this.search()
        // this.paginationByNo(--this.pageNo, this.pageSize)
      }
    },
    // 指南
    curPagezn(i) {
      if (i === "...") return;
      this.pageNozn = i;
      this.$data.subscribeGuideList = this.$data.subscribeGuideListbeifen.slice(
        (i - 1) * 10,
        (i - 1) * 10 + 10
      );
      this.$data.isreadfenyezn = false;
    },
    // 指南
    nextPagezn() {
      if (this.pageNozn < this.pageTotalzn) {
        this.pageNozn = Number(this.pageNozn) + 1;
        const i = this.pageNozn;
        this.$data.subscribeGuideList =
          this.$data.subscribeGuideListbeifen.slice(
            (i - 1) * 10,
            (i - 1) * 10 + 10
          );
        this.$data.isreadfenyezn = false;
        // this.search()
      }
    },
    toreportingGuidelines() {},
    // 跳转到我收藏的文章
    tomyCollectionarticles() {
      this.$router.push({
        path: "myCollectionarticles",
      });
      window._czc.push(["_trackEvent", "点击", "跳转到我的收藏"]);
    },
    // 跳转到我近期浏览过的信息
    tomyRecentInformation() {
      this.$router.push({
        path: "myRecentInformation",
      });
      window._czc.push(["_trackEvent", "点击", "跳转到我近期浏览过的信息"]);
    },
    // 鼠标移入标签添加类名
    tagmouseenter(event) {
      event.currentTarget.classList.add("checked");
    },
    // 鼠标移出标签删除类名
    tagmouseleave(event) {
      event.currentTarget.classList.remove("checked");
    },
    // 鼠标移入显示标签订阅按钮
    subscribetagmouseenter(event, item) {
      // eslint-disable-next-line eqeqeq
      if (item.time_out == 1) {
        console.log(
          event.currentTarget.firstElementChild.nextElementSibling.classList
        );
        event.currentTarget.firstElementChild.nextElementSibling.classList.add(
          "show"
        );
      } else {
      }
    },
    // 鼠标移除隐藏订阅按钮
    subscribetagmouseleave(event) {
      console.log(
        event.currentTarget.firstElementChild.nextElementSibling.classList
      );
      event.currentTarget.firstElementChild.nextElementSibling.classList.remove(
        "show"
      );
    },
    // 点击查看跟多
    getMoreNews () {
     let shuliang1 = this.$data.Historicalrecordlist.length
      if(this.shuliang <= shuliang1){
        this.shuliang = this.shuliang + 5
      } else {
        return
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.vertical-line {
  border-radius: 2.5px;
  width: 5px;
  height: 18px;
  background-color: #9702a3;
  margin-right: 4px;
}
// 版心
.background {
  background-color: #f1f2f6;
  overflow: hidden;
}
.show {
  display: block !important;
}
.typeArea {
  width: 1200px;
  margin: 0 auto;
}
.content {
  margin-top: 27px;
  .content-top {
    background-color: #fff;
    box-sizing: border-box;
    padding: 40px;
    padding-bottom: 29px;
    margin-bottom: 20px;
    .content-item {
      width: 100%;
      margin: 0 auto;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .header-left {
          display: flex;
          justify-content: left;
          align-items: center;
          div:nth-of-type(2) {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 28px;
            color: #5e068c;
            opacity: 1;
          }
        }
        .header-right {
          cursor: pointer;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 20px;
          color: #9d9d9d;
          opacity: 1;
          span {
            color: #990263;
          }
        }
      }
      .content-main {
        table {
          cursor: pointer;
          // width: 100%;
          border-spacing: 0px;
          border-collapse: collapse;
          table-layout: fixed;
          thead {
            tr {
              height: 55px;
              border-bottom: 1px solid #f0f0f0;
              td {
                box-sizing: border-box;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: bold;
                line-height: 21px;
                color: #3b3b3b;
                opacity: 1;
                background-color: #f9f9f9;
              }
              td:nth-of-type(1) {
                text-align: left;
                padding-left: 30px;
                width: 700px;
              }
              td:nth-of-type(2) {
                width: 250px;
              }
              td:nth-of-type(3) {
                text-align: center;
              }
            }
          }
          tbody {
            tr {
              cursor: pointer;
              height: 60px;
              border-bottom: 1px solid #f0f0f0;
              box-sizing: border-box;
              &:hover {
                background-color: #f9f9f9;
              }
              td {
                box-sizing: border-box;
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #282828;
                opacity: 1;
              }
              td[class="asfo"] {
                color: #707070 !important;
              }
              td:nth-of-type(1) {
                text-align: left;
                padding-left: 30px;
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                line-height: 18px;
                color: #990263;
                opacity: 1;
              }
              td:nth-of-type(2) {
                font-weight: 700;
                padding-right: 6px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              td:nth-of-type(3) {
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
    .paging {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 48px;
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
      }
      li {
        cursor: pointer;
        width: 50px;
        height: 50px;
        margin: 0px 4px;
        background-color: #fff;
        // border: 1px solid #b9b9b9;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        a {
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #282828;
        }
      }
      .active {
        background: #5e068c;
        border: 1px solid #5e068c;
        opacity: 1;
        a {
          color: #fff;
        }
      }
    }
  }
  .nocollection {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.z_index {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .left {
    width: calc(100% - 440px);
  }
  .right {
    width: 340px;
  }
}
.z_xgkt {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 40px;
  background: #fff;
  margin-bottom: 170px;
  .title {
    font-size: 18px;
    color: #333333;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    &::before {
      content: "";
      display: block;
      width: 4px;
      height: 12px;
      background: #5e068c;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      margin-right: 10px;
    }
  }
  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    li {
      width: 100%;
      line-height: 1.5;
      margin: 0;
      padding: 0;
      list-style-type: none;
      margin-bottom: 20px;
      cursor: pointer;

      h1 {
        margin: 0;
        margin-bottom: 10px;
        font-size: 14px;
        color: #282828;
        width: 100%;
        font-weight: normal;
      }
      h2 {
        margin: 0;
        font-size: 14px;
        color: #aaaaaa;
        width: 100%;
        font-weight: normal;
      }
    }
  }
}

.z_nav {
  width: 100%;
  background: #fff;
  margin-bottom: 35px;
  .ul {
    display: flex;
    height: 50px;
    .a {
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
      font-size: 14px;
      color: #666666;
      margin-right: 40px;
      cursor: pointer;
      &.active {
        color: #5e068c;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: 0;
          z-index: 9;
          width: 21px;
          height: 2px;
          background: #5e068c;
        }
      }
    }
  }
}

.content-item1 {
  display: flex;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #f0f0f0;
  &:last-child {
    border-bottom: 0;
  }
  .item-tag {
    width: 45px;
    height: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 11px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
  .keti {
    border: 1px solid #990263;
    color: #990263;
    width: 49px;
    height: 23px;
  }
  .zhaobiao {
    border: 1px solid #5e068c;
    color: #5e068c;
    width: 49px;
    height: 23px;
  }
  .jiezhi {
    border: 1px solid #707070;
    color: #707070;
    width: 49px;
    height: 23px;
  }
  .jinxing {
    border: 1px solid #990099;
    color: #990099;
    width: 49px;
    height: 23px;
  }
  .item-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    .content-title {
      display: flex;
      cursor: pointer;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #282828;
      margin-bottom: 20px;
      line-height: 1.5;
      .title-text {
        flex: 1;
        color: #000;
        font-weight: bold;
      }
    }
    .content-info {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px;
      .info-item {
        display: flex;
        margin-right: 48px;
        margin-bottom: 12px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        overflow: hidden;
        white-space: nowrap;
        .fbjg {
          display: block;
          width: 166px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .long {
        width: 240px;
        max-width: 240px;
      }
    }
    .content-tag {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .tag-item {
        padding: 6px;
        background-color: #f5f6fa;
        margin-right: 10px;
        border-radius: 1px;
        margin-bottom: 10px;
      }

      .checked {
        background-color: #5e068c;
        color: #fff;
      }
    }
  }
}
.seemore {
      cursor: pointer;
      margin: 0 auto;
      width: 180px;
      height: 36px;
      border: 1px solid #b9b9b9;
      border-radius: 4px;
      margin-bottom: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #b9b9b9;
    }
    .re{
          width: 16px;
          height: 16px;
          background: linear-gradient(138deg, #FF9B9A 0%, #F44C41 100%);
          border-radius: 2px 2px 2px 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1px;
          opacity: 1;
          font-size: 10px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
          -webkit-text-stroke: 1px rgba(0,0,0,0);
          margin: 0 6px 0 0;
          margin-top: 5px;
        }
        .zhi{
          width: 16px;
          height: 16px;
          background: linear-gradient(138deg, #9F9F9F 0%, #757575 100%);
          border-radius: 2px 2px 2px 2px;
          padding: 1px;
          opacity: 1;
          font-size: 10px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
          -webkit-text-stroke: 1px rgba(0,0,0,0);
          margin: 0 6px 0 0;
        }
</style>
